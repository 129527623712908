import { graphql, StaticQuery } from 'gatsby';
import { getSrc } from "gatsby-plugin-image"
import * as React from 'react';
import styled from '@emotion/styled';

import config from '../../website-config';

const SubscribeOverlayLogo = styled.img`
  position: fixed;
  top: 23px;
  left: 30px;
  height: 30px;
`;

interface SiteNavLogoProps {
  logo?: {
    childImageSharp: any;
  };
}

const SubscribeLogo = () => (
  <StaticQuery
    query={graphql`query SubscribeOverlayLogo {
  logo: file(relativePath: {eq: "img/20200321-Logo Bruhn-Natur.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FIXED)
    }
  }
}
`}
    // tslint:disable-next-line:react-this-binding-issue
    render={(data: SiteNavLogoProps) =>
      data.logo && (
        <SubscribeOverlayLogo src={getSrc(data.logo.childImageSharp.gatsbyImageData)} alt={config.title} />
      )}
  />
);

export default SubscribeLogo;
